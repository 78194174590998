import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
// local
import AdminLayout from "../_layout/AdminLayout";
import {usePermissionsGroups, updateSubscribeProduct} from "../../api/organizations";
import {PermissionsTable} from "./component/PermissionsTable";
import {showSuccess} from "helpers/notifications.helper";

const PermissionsPage = inject("store")(
  observer(({store: {admin}}) => {
    const {subscriptions} = admin;
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [changeLoading, setChangeLoading] = useState([]);
    const [, permissions, permissionsLoading] = usePermissionsGroups();

    const preparedPermissions = useMemo(
      () =>
        permissions
          ? permissions[0].Permissions.map((permission) => ({
              ...permission,
              ...subscriptions?.reduce(
                (acc, subscription) =>
                  Object.assign(acc, {
                    [subscription.name]: {
                      checked: subscription.SubscribeProductPermissions.map(
                        (item) => item.PermissionId
                      ).includes(permission.id),
                      quantity:
                        subscription.SubscribeProductPermissions.find(
                          (item) => item.PermissionId === permission.id
                        )?.quantity ?? null,
                      subscriptionId: subscription.id,
                    },
                  }),
                {}
              ),
            }))
          : [],
      [permissions, subscriptions]
    );

    const handlePermissionChange = (subscriptionId, permissionId, checked, quantity) => {
      setChangeLoading([subscriptionId, permissionId]);
      const currentSubscribeProductPermissions = subscriptions
        .find((subscription) => subscription.id === subscriptionId)
        .SubscribeProductPermissions.filter(
          (permission) => permission.PermissionId !== permissionId
        );
      const payloadPermissions = checked
        ? currentSubscribeProductPermissions.concat({
            PermissionId: permissionId,
            quantity,
          })
        : currentSubscribeProductPermissions;
      updateSubscribeProduct(subscriptionId, payloadPermissions)
        .then(() => {
          setLoading(true);
          admin
            .fetchSubscriptionsInfo()
            .then(() => setChangeLoading([]))
            .finally(() => setLoading(false));
          showSuccess();
        })
        .catch(() => setChangeLoading([]));
    };

    return (
      <AdminLayout>
        <div className="content-box">
          <h1>Subscription permissions</h1>
          <PermissionsTable
            subscriptionColumns={
              subscriptions?.map((subscription) => ({
                title: `${t(subscription.name)} (${subscription.name.slice(-2)})`,
                key: subscription.name,
                dataIndex: subscription.name,
                filtered: true,
                className: "subscription-column",
              })) ?? []
            }
            loading={loading || permissionsLoading}
            checkLoading={changeLoading}
            dataSource={preparedPermissions}
            onChange={handlePermissionChange}
            pagination={false}
          />
        </div>
      </AdminLayout>
    );
  })
);

export default PermissionsPage;
