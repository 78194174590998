import React, {useCallback, useState, useMemo} from "react";
import {Link, useParams} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {
  Form,
  Input,
  Button,
  Collapse,
  Descriptions,
  Divider,
  Skeleton,
  Statistic,
  Table,
  Row,
  Col,
} from "antd";
import AdminLayout from "../_layout/AdminLayout";
import {toCompany, toOrganization} from "../../constants/routes.const";
import {
  findExistingUser,
  addUserToOrganization,
  changeUserRole,
  changeUserSubscriptionRole,
  removeUser,
  useOrganization,
  updateOrganization,
  useStatuses,
  setSubscription,
  setSubscriptionOnboardingAC,
  setStatus,
  setDaluxPossibility,
  setCSMonitoringPossibility,
  setNotes,
  useRFPBuyerRequest,
  useRFPOffers,
  useRFPSellerProposals,
  addExistingUserToOrg,
} from "../../api/organizations";

import {UsersList} from "./components/UsersList";
import {Subscription} from "./components/Subscription";
import {MonthlyCredits} from "./components/MonthlyCredits";
import {OrganizationLogo} from "./components/OrganizationLogo";
import {Status} from "./components/Status";
import {Dalux} from "./components/Dalux";
import {CSMonitoring} from "./components/CSMonitoring";
import {InviteForm} from "./components/InviteForm";
import {showSuccess} from "helpers/notifications.helper";
import {isEqualArrays} from "helpers/helper";

const rfpColumns = [
  {title: "ID", key: "id", dataIndex: "id", render: (id) => id.slice(0, 8)},
  {title: "Name", key: "name", dataIndex: "name"},
  {
    title: "Closes at (CET)",
    key: "date",
    dataIndex: "closedAt",
    render: (date) => new Date(date).toLocaleString("sv"),
  },
  {title: "Status", key: "status", dataIndex: "status"},
  {
    title: "Description",
    key: "description",
    dataIndex: "description",
    render: (description) => description.replace(/<[^>]+>/g, ""),
  },
  {title: "Standard agreement", key: "standardAgreement", dataIndex: "standardAgreement"},
];

const proposalColumns = [
  {
    title: "RFT id",
    key: "id",
    dataIndex: "Request",
    render: (request) => {
      return `${request?.id.slice(0, 8)}`;
    },
  },
  {
    title: "RFT Name",
    key: "name",
    dataIndex: "Request",
    render: (request) => {
      return `${request?.name.slice(0, 25)}`;
    },
  },

  {
    title: "Readed",
    key: "readed",
    name: "readed",
    dataIndex: "readed",
    render: (readed) => (readed ? "YES" : "NO"),
  },
  {
    title: "Interested",
    key: "interested",
    name: "interested",
    dataIndex: "interested",
    render: (interested) => (interested == null ? "-" : interested ? "YES" : "NO"),
  },
  {
    title: "Created (CET time)",
    key: "created",
    dataIndex: "createdAt",
    render: (date) => new Date(date).toLocaleString("sv"),
  },
  {
    title: "Proposal id",
    key: "id",
    dataIndex: "id",
    render: (id) => id.slice(0, 8),
  },
  {
    title: "Received from",
    key: "request",
    name: "request",
    dataIndex: "Request",
    render: (request) => (
      <Link to={toOrganization(request?.BuyerId)}>
        {request?.Buyer?.name.slice(0, 20)}
      </Link>
    ),
  },
];

const offersColumns = [
  {title: "ID", key: "id", dataIndex: "id", render: (id) => id.slice(0, 8)},
  {
    title: "Proposal",
    key: "proposal",
    name: "proposal",
    dataIndex: "ProcurementProposal",
    render: (proposal) => (
      <Link to={toOrganization(proposal?.SellerId)}>{proposal?.id?.slice(0, 8)}</Link>
    ),
  },
  {title: "Message", key: "message", dataIndex: "message"},
  {title: "Price", key: "price", dataIndex: "price"},
  {
    title: "Readed",
    key: "readed",
    dataIndex: "readed",
    render: (readed) => (readed ? "YES" : "NO"),
  },
  {
    title: "Evaluate",
    key: "evaluate",
    dataIndex: "evaluate",
    render: (evaluate) => (evaluate == null ? "-" : evaluate ? "YES" : "NO"),
  },
  {
    title: "Created (CET time)",
    key: "created",
    dataIndex: "createdAt",
    render: (date) => new Date(date).toLocaleString("sv"),
  },
  {
    title: "Valid until (CET time)",
    key: "validUntil",
    dataIndex: "validUntil",
    render: (date) => new Date(date).toLocaleString("sv"),
  },
];

const OrganizationPage = inject("store")(
  observer(({store: {admin}}) => {
    const {subscriptions, subscriptionRoles} = admin;
    const [userData, setUserData] = useState({
      id: "",
      email: "",
      firstName: "",
      lastName: "",
    });

    const resetUserData = () => {
      setUserData({
        id: "",
        email: "",
        firstName: "",
        lastName: "",
      });
    };

    const [addExistingUserForm] = Form.useForm();
    const [addUserForm] = Form.useForm();
    const [addNoteForm] = Form.useForm();
    const [, setFilter] = useState({});
    const {id} = useParams();
    const [, organizationInfo, loading, reloadOrganization] = useOrganization(id);
    const [, statuses] = useStatuses();
    const [, buyersRFP, buyersRFPLoading] = useRFPBuyerRequest(id);
    const [, sellerRFP, sellerRFPLoading] = useRFPSellerProposals(id);
    const [, offers, offersLoading] = useRFPOffers(id);

    const organization = organizationInfo?.organization ?? null;
    const organizationId = organization?.id;
    const invites = organizationInfo?.invites ?? null;
    const activeSubscriptions = useMemo(
      () =>
        organization?.SubscribeUnit?.SubscribeProducts?.sort((a, b) =>
          a.name > b.name ? 1 : -1
        ) ?? [],
      [organization?.SubscribeUnit]
    );

    const regionsCount = organization?.SearchPreference?.regionsCount;

    const onUserRemove = useCallback((userId) => {
      removeUser(id, userId).then(reloadOrganization);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUserChangeSubscriptionRole = useCallback(
      (userId, role) => {
        changeUserSubscriptionRole(id, userId, role).then(reloadOrganization);
      },
      [id, reloadOrganization]
    );

    const onUserChangeRole = useCallback(
      (userId, role) => {
        changeUserRole(id, userId, role).then(() =>
          role === "admin"
            ? onUserChangeSubscriptionRole(
                userId,
                subscriptionRoles.find((item) => item.id === "USER_ROLE_SUB_05_BUYER").id
              )
            : reloadOrganization()
        );
      },
      [id, reloadOrganization, subscriptionRoles, onUserChangeSubscriptionRole]
    );

    const onExistingUserReset = useCallback(() => {
      addExistingUserForm.resetFields();
      setFilter({
        email: "",
      });
      resetUserData();
    }, [addExistingUserForm]);

    const onReset = useCallback(() => {
      addUserForm.resetFields();
      setFilter({
        firstName: "",
        lastName: "",
        email: "",
        mobilePhone: "",
        organizationId: "",
      });
    }, [addUserForm]);

    const onaddUser = useCallback((obj) => {
      addUserToOrganization({
        obj,
        organizationId: id,
      })
        .then(addUserForm.resetFields())
        .then(reloadOrganization)
        .catch((e) => e);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSearchExistingUser = useCallback(async (obj) => {
      try {
        const response = await findExistingUser(obj);
        addExistingUserForm.resetFields();
        setUserData({
          id: response.data.id,
          email: response.data.email,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
        });
      } catch (error) {
        onExistingUserReset();
        return error;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addExistingUser = useCallback(async () => {
      const currentUserData = {...userData};
      try {
        addExistingUserForm.resetFields();
        await addExistingUserToOrg({...currentUserData, organizationId: id});
        reloadOrganization();
        onExistingUserReset();
      } catch (error) {
        onExistingUserReset();
        return error;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData, addExistingUserToOrg, id]);

    const onaddNote = useCallback(
      (obj) => setNotes(organizationId, obj.note).then(() => showSuccess()),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [organizationId]
    );

    const onChangeSubscriptions = (values) => {
      const activeSubscribeProducts = activeSubscriptions
        .sort((a, b) => (a.id > b.id ? 1 : -1))
        .map((item) => ({
          subscribeProductId: item.id,
          pricePeriod: item.SubscribeUnitProduct.pricePeriod,
        }));
      isEqualArrays(values.subscribeProducts, activeSubscribeProducts)
        ? updateOrganization(organizationId, values).then(() => showSuccess())
        : setSubscription(organizationId, values).then(() => showSuccess());
    };

    const onChangeOnboardingAC = (values) =>
      setSubscriptionOnboardingAC(organizationId, values).then(() => showSuccess());

    const onChangeCredits = (values) =>
      updateOrganization(organizationId, values).then(() => showSuccess());

    return (
      <AdminLayout>
        <div className="content-box">
          {loading && <Skeleton active />}
          {organization && (
            <Descriptions
              title={<h1>{`${organization.name}`}</h1>}
              bordered
              column={1}
              labelStyle={{width: 200}}
            >
              <Descriptions.Item label="ID">{organization.id}</Descriptions.Item>
              <Descriptions.Item label="Formal company name">
                <Link to={toCompany(organization.CompanyId)}>
                  {organization.Company.companyName}
                </Link>
              </Descriptions.Item>
              <Descriptions.Item label="Created (CET time)">
                {new Date(organization.createdAt).toLocaleString("sv")}
              </Descriptions.Item>
              {/* <Descriptions.Item label="Latest update (CET time)">
              {new Date(organization.updatedAt).toLocaleString("sv")}
            </Descriptions.Item> */}
              <Descriptions.Item label="Status">
                <Status
                  activeStatus={organization.status}
                  statuses={statuses}
                  regionsCount={
                    regionsCount === 23 ? "FIRST_SETUP_REGION_00" : regionsCount
                  }
                  submitStatus={(status) =>
                    setStatus(organizationId, status).then(() => showSuccess())
                  }
                />
              </Descriptions.Item>
              <Descriptions.Item label="Dalux">
                <Dalux
                  activeStatus={organization.enableDaluxIntegration}
                  submitStatus={(status) =>
                    setDaluxPossibility(organizationId, status).then(() => showSuccess())
                  }
                />
              </Descriptions.Item>
              <Descriptions.Item label="CS Monitoring">
                <CSMonitoring
                  activeStatus={organization.allowMonitorSuppliers}
                  companiesCount={organization.monitorSuppliersCompaniesCount}
                  usersCount={organization.monitorSuppliersUsersCount}
                  submitStatus={(status) =>
                    setCSMonitoringPossibility(organizationId, status).then(() =>
                      showSuccess()
                    )
                  }
                />
              </Descriptions.Item>
              <Descriptions.Item label="Subscription">
                {!!subscriptions && (
                  <Subscription
                    activeSubscriptions={activeSubscriptions}
                    subscriptions={subscriptions?.filter((item) => item.allow)}
                    submitSubscription={onChangeSubscriptions}
                    email={organization.paymentEmail}
                    reference={organization.invoiceReference}
                    users={organization?.Users}
                    submitOnboardingAC={onChangeOnboardingAC}
                  />
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Monthly credits">
                <MonthlyCredits
                  monthlyCredits={organization.creditsUpdateLimit}
                  usedCredits={organization.creditsUpdateUsed}
                  submitCredits={onChangeCredits}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Logo">
                <OrganizationLogo
                  activeLogo={organization?.OrganizationMainLogoFile?.File?.url}
                  organizationId={organization?.id}
                />
              </Descriptions.Item>
            </Descriptions>
          )}

          <Divider orientation="left">Notes</Divider>
          {organization && (
            <Form
              initialValues={{note: organization?.notes ?? ""}}
              form={addNoteForm}
              layout="inline"
              onFinish={onaddNote}
            >
              <Form.Item name="note" style={{flexGrow: 2}}>
                <Input.TextArea
                  autoSize={{minRows: 2, maxRows: 6}}
                  placeholder="Input notes"
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save note
                </Button>
              </Form.Item>
            </Form>
          )}

          <Divider orientation="left">
            {`Users (${organization?.Users?.length ?? 0})`}
          </Divider>
          <UsersList
            users={organization?.Users}
            organizationId={id}
            subscriptionRoles={subscriptionRoles}
            onUserChangeRole={onUserChangeRole}
            onUserChangeSubscriptionRole={onUserChangeSubscriptionRole}
            onUserRemove={onUserRemove}
          />

          <Divider orientation="left">Add existing user to the organization</Divider>

          <Form
            form={addExistingUserForm}
            layout="inline"
            onFinish={onSearchExistingUser}
          >
            <Form.Item name="email">
              <Input type="email" placeholder="Search by Email" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
            <Form.Item>
              <Button htmlType="button" onClick={onExistingUserReset}>
                Reset
              </Button>
            </Form.Item>
          </Form>

          {userData.email && (
            <div>
              {`${userData.email} - ${userData.firstName} ${userData.lastName}`}
              <Button type="primary" onClick={addExistingUser}>
                Add user
              </Button>
            </div>
          )}

          <Divider orientation="left">Create new organization user</Divider>
          <Form form={addUserForm} layout="inline" onFinish={onaddUser}>
            <Form.Item name="firstName">
              <Input placeholder="First name" />
            </Form.Item>
            <Form.Item name="lastName">
              <Input placeholder="Last name" />
            </Form.Item>
            <Form.Item name="email">
              <Input type="email" placeholder="Email" />
            </Form.Item>
            <Form.Item name="mobilePhone">
              <Input maxLength="11" placeholder="Mobile phone" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create new user
              </Button>
            </Form.Item>
            <Form.Item>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
            </Form.Item>
          </Form>
          {buyersRFP && buyersRFP.length > 0 && (
            <>
              <Divider orientation="left">
                {`Created RFPs (${buyersRFP?.length ?? 0})`}
              </Divider>
              <Table
                rowKey="id"
                columns={rfpColumns}
                dataSource={buyersRFP}
                pagination={false}
                loading={buyersRFPLoading}
              />
            </>
          )}

          {sellerRFP && sellerRFP.length > 0 && (
            <>
              <Divider orientation="left">{`Received RFTs (${
                sellerRFP?.length ?? 0
              })`}</Divider>
              <Table
                rowKey="id"
                columns={proposalColumns}
                dataSource={sellerRFP}
                pagination={false}
                loading={sellerRFPLoading}
              />
            </>
          )}
          {(offersLoading || (offers && offers.length > 0)) && (
            <>
              <Divider orientation="left">{`Offers (${offers?.length ?? 0})`}</Divider>
              <Table
                rowKey="id"
                columns={offersColumns}
                dataSource={offers}
                pagination={false}
                loading={offersLoading}
              />
            </>
          )}
          {invites && (
            <>
              <Divider orientation="left">Organization Invites</Divider>
              <Row>
                <Col span={4}>
                  <Statistic title="Sent Invites" value={invites.summary.total} />
                </Col>
                <Col>
                  <Statistic
                    title="Signed Invites"
                    value={invites.summary.signed}
                    valueStyle={{color: "#3f8600"}}
                  />
                </Col>
              </Row>
            </>
          )}
          <Divider orientation="left">Invite companies with CSV file upload</Divider>
          <Collapse ghost>
            <Collapse.Panel header="Click to open/close" key="1">
              <InviteForm organizationId={id} />
            </Collapse.Panel>
          </Collapse>
        </div>
      </AdminLayout>
    );
  })
);

export default OrganizationPage;
