import React, {useState} from "react";
import {Table} from "antd";
import {Checkbox, InputNumber} from "antd";
import {useTranslation} from "react-i18next";
// local
import {Preloader} from "components/Preloader";
import {CustomTooltip} from "components/CustomTooltip";

export const PermissionsTable = ({
  subscriptionColumns,
  onChange,
  checkLoading,
  ...props
}) => {
  const {t} = useTranslation();

  const columns = [
    {
      title: "Permissions",
      key: "name",
      dataIndex: "name",
      width: 300,
      className: "permissions-column",
      render: (id, permission) => (
        <CustomTooltip
          text={`ID: ${permission.id}\n\n${t("DESCRIPTION")}: ${permission.description}`}
        >
          {id}
        </CustomTooltip>
      ),
    },
  ];

  const SubscriptionColumn = ({subscriptionId, permissionId, checked, quantity}) => {
    const [inputQuantity, setInputQuantity] = useState(quantity);

    return checkLoading[0] === subscriptionId && checkLoading[1] === permissionId ? (
      <Preloader size="small" addClass="mt-0" />
    ) : (
      <div className="df-row-center">
        <Checkbox
          checked={checked}
          disabled={false}
          onChange={(e) =>
            onChange(subscriptionId, permissionId, e.target.checked, inputQuantity)
          }
        />
        {checked && (
          <span className="quantity-block pl-3 fz-12">
            qty:{" "}
            <InputNumber
              value={inputQuantity}
              size="small"
              onChange={(value) => {
                onChange(subscriptionId, permissionId, true, value);
                setInputQuantity(value);
              }}
              style={{width: "40%"}}
            />
          </span>
        )}
        {checked && !!inputQuantity && (
          <span className="quantity-hover pl-3 fz-12">quantity: {inputQuantity}</span>
        )}
      </div>
    );
  };

  return (
    <Table
      rowKey="name"
      sticky
      size="small"
      {...props}
      columns={[
        ...columns,
        ...subscriptionColumns.map((item) => ({
          ...item,
          render: (item, record) => (
            <SubscriptionColumn
              checked={item.checked}
              quantity={item.quantity}
              permissionId={record.id}
              subscriptionId={item.subscriptionId}
            />
          ),
        })),
      ]}
      className="permissions"
    />
  );
};
