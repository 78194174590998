import React from "react";
import {Link} from "react-router-dom";
import {Button, List, Select} from "antd";
import {useTranslation} from "react-i18next";

export const UsersList = ({
  users,
  subscriptionRoles,
  onUserRemove,
  onUserChangeRole,
  onUserChangeSubscriptionRole,
}) => {
  const {t} = useTranslation();

  const {Option} = Select;

  const userActions = (user) => [
    <Select
      value={user.OrganizationMember.role}
      onChange={(value) => onUserChangeRole(user.id, value)}
      key={"role"}
    >
      <Option value={"member"}>Member</Option>
      <Option value={"admin"}>Admin</Option>
      <Option value={"owner"}>Owner</Option>
    </Select>,
    <Select
      value={user.OrganizationMember.SubscribeRoleId}
      onChange={(value) => onUserChangeSubscriptionRole(user.id, value)}
      key={"SubscribeRoleId"}
      options={subscriptionRoles?.map((item) => ({
        value: item.name,
        label: t(item.name),
      }))}
    />,
    <Button danger onClick={() => onUserRemove(user.id)} key={"remove"}>
      Remove
    </Button>,
  ];

  const renderUser = (user) => (
    <List.Item actions={userActions(user)}>
      <Link to={`/user/${user.id}`}>{`${user.firstName} ${user.lastName}`}</Link> -
      mobile: {user.mobilePhone} - email: {user.email}
    </List.Item>
  );

  return (
    <List
      itemLayout="horizontal"
      dataSource={users}
      renderItem={renderUser}
      pagination={false}
      rowKey={(row) => row.id}
    />
  );
};
