import React, {useState} from "react";
import {Button, Input} from "antd";

export const MonthlyCredits = ({monthlyCredits, usedCredits, submitCredits}) => {
  const [currentCredits, setCurrentCredits] = useState(monthlyCredits);
  const remainCredits =
    currentCredits - usedCredits < 0 ? 0 : currentCredits - usedCredits;

  return (
    <div>
      <div className="df-row-center">
        <Input
          placeholder="Number of credits"
          value={currentCredits}
          onChange={(e) => setCurrentCredits(e.target.value)}
          style={{width: "15%"}}
          className="mr-2"
        />
        <span>{`${usedCredits} used / ${remainCredits} remaining this month`}</span>
        <Button
          type="primary"
          onClick={() =>
            submitCredits({
              creditsUpdateMonthly: currentCredits,
            })
          }
          disabled={+monthlyCredits === +currentCredits}
          className="ml-2"
        >
          Set credits
        </Button>
      </div>
    </div>
  );
};
